// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-vue/dist/bootstrap-vue.css";

// Plugins
@import "~sweetalert2/src/sweetalert2";
@import "~flatpickr/dist/flatpickr.css";
@import "~@fortawesome/fontawesome-svg-core/styles.css";
@import "~intl-tel-input/src/css/intlTelInput";

// Common CSS WITH VODAPP
@import "../vueapp/header";
@import "../vueapp/footer";


// Components
@import "../buttons";

// Pages
@import "blog";

// RTL
@import "../bootstrap-rtl";

@import "../vendor/tabler/scss/dashboard/fonts/feather";

@font-face {
	font-family: "Source Sans Pro";
	src: url('../../fonts/source-sans-pro-v11-latin-regular.eot?t=1501841394106');
	src: url('../../fonts/source-sans-pro-v11-latin-regular.eot?t=1501841394106#iefix') format('embedded-opentype'),
  url('../../fonts/source-sans-pro-v11-latin-regular.woff?t=1501841394106') format('woff'),
  url('../../fonts/source-sans-pro-v11-latin-regular.ttf?t=1501841394106') format('truetype'),
  url('../../fonts/source-sans-pro-v11-latin-regular.svg?t=1501841394106#feather') format('svg');
  font-style: normal;
}

@font-face {
	font-family: "Material Icons";
	src: url('../../fonts/material-icons/MaterialIcons-Regular.eot');
	src: url('../../fonts/material-icons/MaterialIcons-Regular.eot?t=1501841394106#iefix') format('embedded-opentype'),
  url('../../fonts/material-icons/MaterialIcons-Regular.woff?t=1501841394106') format('woff'),
  url('../../fonts/material-icons/MaterialIcons-Regular.ttf?t=1501841394106') format('truetype'),
  url('../../fonts/material-icons/MaterialIcons-Regular.svg?t=1501841394106#feather') format('svg');
  font-style: normal;
}

body {
  margin:0;
  padding:0;
  background-color: #f2f2f2;
  color:#000;
  font-family: "Source Sans Pro";
  width: 100%;
  min-height: 100%;
  .content {
	min-height: 60vh;
  }
}

.form-control {
	&.flatpickr-input {
		background-color: #fff;
	}
	&.flatpickr-alt-input {
		background-color: #fff;
		border-top-left-radius: 5px !important;
		border-bottom-left-radius: 5px !important;
	}
}

.custom-inline-feedback {
	display: inline !important;
}

.cat-row {
  margin-bottom: 80px;
  > h2{
    font-size: 23px;
    margin-bottom: 10px;
    margin-left: 4vw;
    position: relative;
    overflow: hidden;
    font-weight: 600;
    &:after {
      content: ' ';
      border-bottom: 1px solid #fff;
      width: 100%;
      display: inline-block;
      position: absolute;
      bottom: 5px;
      margin-left: 5px;
    }
  }
}

.navbar.bg-dark{
  background-color: rgba(15, 31, 43, 0.83) !important;
  .container{
    max-width: 1900px;
  }
  .navbar-brand {
    img {
      vertical-align: sub;
    }
    span {
      margin-left: 10px;
      text-transform: uppercase;
    	font-weight: bold;
    }
  }
}

.highlight {
  position: relative;
}

.jumbotron {
  margin-bottom: 0;
  direction: ltr !important;

  @include media-breakpoint-up(sm) {
    padding: $jumbotron-padding $jumbotron-padding / 2;
  }
}

.breadcrumb {
  padding: 1rem 0;
  background-color: transparent;
}

.breadcrumb-item.active {
  color: $white;
}

[v-cloak] {
  display: none;
}

.intl-tel-input {
  width: 100%;
}

iframe {
  width: 100%;
  border: 0;
}

.alert.alert-top {
  border: none;
  margin-bottom: 0;
}

.custom-control-indicator, .custom-control-description, .switch-label, .switch-handle {
  margin-top: .25rem;
}

[data-toggle="slider"]:not(.slick-initialized) {
  visibility: hidden;
}

.wysiwyg-content .image {
  &.image-style-align-left {
    float: left;
    margin-right: 2em;
  }

  &.image-style-align-right {
    float: right;
    margin-left: 2em;
  }

  &.image-style-align-center {
    margin-left: auto;
    margin-right: auto;
  }

  &.image-style-align-center, .image-style-align-left, .image-style-align-right {
    max-width: 50%;
  }

  > img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  > figcaption {
    text-align: center;
    color: #333;
    background-color: #f7f7f7;
    padding: .8em;
    font-size: .75em;
    outline-offset: -1px;
  }
}

.white-page {
	background-color: #fff;
	color: #000;
}

.login-page,
.register-page {
	background-position: center;
	background-size: cover;
	.register-title {
		h2 {
			margin-bottom: 40px;
			font-weight: bold;
			span {
				color:#24baef;
			}
		}
		h3 {
			margin-bottom: 60px;
			font-size: 24px;
		}
	}
	.register-terms {
		margin-top: 60px;
		font-size: 16px;
	}
	.connect-with {
		margin-top: 30px;
		margin-bottom: 40px;
		h6 {
			display: block;
			font-size: 18px;
			text-transform: uppercase;
			margin-bottom: 30px;
			font-weight: bold;
		}
		a.btn {
			transform: scale(1.4);
		}
	}
	.g-recaptcha {
	  @include media-breakpoint-down(xs) {
	    transform: scale(0.82);
	    transform-origin: 0;
		}
	}
	.card {
		background-color: rgba(255, 255, 255, 0.85);
		.custom-control-label{
			&::before {
				background-color: #c0c3c5;
			}
		}
		.progress {
			margin-top: 4px;
		}
	}
}

/****** User Pages *********/

.page-user {
	.collapse-toogle {
		padding-left: 10px;
		background-color: #343a40;
		color: #fff;
		position: relative;
		border-radius: 5px;
		cursor: pointer;
		svg {
			position: absolute;
			right: 10px;
			top: 4px;
		}
	}
}

/****** End User Pages *********/

/****** Checkout Page *********/

.checkout {
	.help-text {
		font-size:12px;
		color:#fff;
		a {
			color: #fff;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.card {
		cursor: pointer;
		background-color: rgba(255, 255, 255, 1);
		.card-header {
			background-color: rgba(255, 255, 255, 1);
		}
		&.active,
		&.hover {
			background-color: rgba(36, 186, 239, 1);
			.card-header {
				background-color: rgba(36, 186, 239, 1);
			}
			.pricing-card-title {
				color: #fff;
			}
			.card-body {
				color: #fff;
			}
		}
		&.not-hover {
			background-color: rgba(255, 255, 255, 1);
			.card-header {
				background-color: rgba(255, 255, 255, 1);
			}
			.pricing-card-title {
				color: #000;
			}
			.card-body {
				color: #000;
			}
		}
		.card-header {
			text-transform: uppercase;
			h4 {
				text-transform: uppercase;
				font-weight: bold !important;
				font-size: 26px;
				letter-spacing: 0.5px;
			}
		}
		.card-body {
			font-size: 12px;
			font-weight: bold;
			.pricing-card-title {
    		font-size: 84px;
				em {
					font-size: 32px;
					font-weight: bold;
					vertical-align: super;
				}
				.month {
			    color: #000 !important;
			    font-size: 22px;
			    margin-left: -50px;
					font-weight: bold;
				}
			}
		}
		&.plan_2 {
			.card-body {
				.pricing-card-title {
					.month {
						color: #000 !important;
						font-size: 22px;
						margin-left: -70px;
						font-weight: bold;
					}
				}
			}
		}
		&.plan_3 {
			.card-body {
				.pricing-card-title {
	    		font-size: 54px;
				}
			}
		}
	}
	#selected_plan {
		text-transform: capitalize;
	}
	.free-desc {
		font-size: 11px;
	}
	.loading-spinner {
    width: 60px;
    height: 60px;
    margin: 0 auto;
	}
}

/******** STRIPE **********/
.spacer {
		margin-bottom: 24px;
}
/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
	background-color: white;
	padding: 10px 12px;
	border-radius: 4px;
	border: 1px solid #ccd0d2;
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
	-webkit-transition: box-shadow 150ms ease;
	transition: box-shadow 150ms ease;
}
.StripeElement--focus {
	box-shadow: 0 1px 3px 0 #cfd7df;
}
.StripeElement--invalid {
	border-color: #fa755a;
}
.StripeElement--webkit-autofill {
	background-color: #fefde5 !important;
}
#card-errors {
		color: #fa755a;
}

/****** MSTEPPER OVERRIDES *********/
ul {
	&.stepper {
		&.horizontal {
			&::before {
				box-shadow: none;
			}
			.step {
				list-style: none;
				&::before {
					background-color: transparent;
					border: 1px solid #fff;
			    height: 31px;
			    width: 31px;
    			line-height: 28px;
					font-size: 16px;
				}
				&:not(:last-of-type){
					&::after {
    				background-color: rgba(255,255,255,.5);
    				left: 14.5px;
					}
				}
				&.active {
					.step-title {
						color: #24baef !important;
						&::before {
							color: #24baef !important;
							border-color: #24baef !important;
						}
					}
				}
				&.wrong {
					.step-title {
						&::before {
							content: "\e645";
							font-size: 16px;
					    font-family: 'Material Icons';
					    background-color: red;
					    line-height: 30px;
					    text-align: center;
					    width: 32px;
						}
					}
				}
				.step-title {
					position: relative !important;
					&::after {
						color: #fff !important;
					}
					&::before {
						background-color: transparent !important;
						border: 1px solid #fff;
				    height: 32px;
				    width: 32px;
	    			line-height: 28px;
						font-size: 16px;
						margin-top: -2px;
					}
				}
				.step-content {
					padding-bottom: 0px;
				}
			}
		}
	}
}

/* DARK MODE */
.card {
	color:#000;
	img {
		max-width: 100%;
	}
}

/****************** Responsive *********************/

/* header */

nav.navbar{
	@include media-breakpoint-down(xs) {
		.navbar-toggler {
			border: 0px solid transparent;
	    font-size: 15px;
	    padding: 0;
		}
		.navbar-collapse.main-navbar {
			position: absolute;
			left: 0;
			right: 0;
			top: 100%;
	    bottom: -100vh;
	    background-color: rgba(0, 0, 0, 0.7);
			.navbar-nav {
				padding-bottom: 5px;
				padding-top: 5px;
		    flex-direction: column !important;
				background-color: black;
				.nav-link {
					padding-left: 15px;
    			font-size: 19px;
			    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
			    color: #fff;
					cursor: pointer;
				}
			}
		}
		.navbar-nav.member-nav{
	    position: absolute;
	    right: 0;
	    -webkit-box-orient: horizontal;
	    -webkit-box-direction: normal;
	    -ms-flex-direction: row;
	    flex-direction: row;
			.search {
				margin-right: 5px;
				&.active {
					input {
						width: 190px
					}
				}
			}
		}
	}
}

/* Footer */

footer {
	@include media-breakpoint-down(xs) {
		padding: 20px;
		.claim {
			h4 {
				font-size: 14px;
			}
			img {
		    width: 40px;
	  		margin-right: 10px;
		    height: auto;
			}
		}
		.follow-us {
			.text-lg-right {
				margin-top: 10px;
				a {
					margin-right: 10px;
					svg {
				    width: 34px !important;
				    height: 34px;
					}
				}
			}
		}
		.footer-links {
			font-size: 14px;
		}
	}
}

/*** PAGES ***/

/* Home */

.touch-device {
	.cat-row {
		overflow: hidden;
	}
	.carousel-container {
	  margin-bottom: -17px;
	  overflow: hidden;
		.slide-button {
			visibility: hidden;
		}
		.slider-wrapper {
			overflow-x: auto;
			-webkit-overflow-scrolling: touch;
			.slider {
				padding-right: 8vw;
			}
		}
	}
}

.staytune {
	@include media-breakpoint-down(sm) {
		.row {
			margin: 0;
			h2 {
				font-size: 26px;
			}
		}
	}
}

/* Watch */

.watch-container {
	@include media-breakpoint-down(sm) {
		.video-container {
			.back-button {
		    top: 20px;
		    left: 20px;
				svg {
			    width: 40px;
				}
			}
			.infos-button {
		    top: 20px;
		    right: 20px;
				svg {
			    width: 40px;
				}
			}
		}
		.video-overlay {
			padding-top: 80px;
			.content {
				height: auto;
				.video-infos.center {
					position: relative;
					top: auto;
					left: auto;
					transform: none;
					padding: 30px;
					h1.title {
						font-size: 32px;
					}
				}
			}
		}
	}
}
